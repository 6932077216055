<template>
    <div class="page_content">
        <!-- <a-spin size="large" v-show="loading" /> -->
        <div class="page_edit_content">
            <Form ref="form" :form_data="form_data" @handleSubmit="handleSubmit" >
            </Form>
        </div>

    </div>
</template>

<script>
    import Form from "@/components/Form";
    import SelectUser from "@/components/SelectUser";
    import SelectCustomer from "@/components/SelectCustomer";
    import EditPop from "@/components/EditPop";
    import warehouse from "@/components/EditPop";
    import Map from "@/components/EditPop";
    import SimpleMap from "@/components/BaiduMap";
    import {
        getModifyCustomerInfo,
        cancelBinding,
        getCustomerDetails,
        modify
    } from "@/api/customer";

    export default {
        components: {
            Form,
            SelectUser,
            EditPop,
            SelectCustomer,
            warehouse,
            Map,
            SimpleMap
        },
        data() {
            return {
                form_data: {
                    title: "版本编号",
                    show_submit_btn: true,
                    show_close_btn: true,
                    close_reset_btn: true,
                    list: [{
                            type: "text",
                            name: "id",
                            hidden: true,
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "name",
                            title: "客户名称",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请输入客户名称"
                                }],
                                initialValue: ""
                            }
                        },
                        {
                            type: "radio",
                            name: "status",
                            title: "客户状态",
                            relation_name: 'close_desc',
                            mode: "default",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择客户状态"
                                }],
                                initialValue: "1"
                            },
                            list: [{
                                    key: "1",
                                    value: "运营中"
                                },
                                {
                                    key: "-1",
                                    value: "封存"
                                }
                            ]
                        },

                        {
                            type: "text",
                            name: 'close_desc',
                            title: "封存原因",
                            close: true,
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请填写封存原因"
                                }],
                                initialValue: ""
                            }
                        },
                        {
                            type: "select",
                            name: "region_type",
                            title: "城乡类型",
                            mode: "default",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择客户城乡类型"
                                }],
                                initialValue: ""
                            },
                            list: []
                        },
                        {
                            type: "cascader",
                            name: "group_id",
                            title: "客户分组",
                            list: [],
                            placeholder: "请选择客户分组",
                            mode: "default",
                            options: {
                                initialValue: []
                            }
                        },
                        {
                            type: "text",
                            name: "tax_number",
                            title: "工商注册号",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "legal_entity",
                            title: "法人姓名",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "business_area",
                            title: "营业面积（m^2）",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "cashier_num",
                            title: "收银台个数",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "shelves_num",
                            title: "货架组数",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "count_put_num",
                            title: "总排面数",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "put_num",
                            title: "润田排面数",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "business_hours",
                            title: "营业时间",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "checkbox",
                            name: "facility",
                            title: "门店设施",
                            options: {
                                initialValue: []
                            },
                            list: []
                        },
                    ]
                },
            };
        },
        async created() {
        },
        methods: {
            submit({
                data
            }) {
                this.visible = false;
                this.select_user = data;
                this.$refs.form.setFieldsValue({
                    uid: this.select_user.id
                });
            },
            handleSubmit(e) {
                let value = e.values;

                let arr = {};
                this.datas.facility_list.forEach(item => {
                    arr[item.id] = false;
                    value.facility.forEach(vo => {
                        if (vo == item.id) {
                            arr[vo] = true;
                        }
                    });
                });
                let contact_person = []
               
                this.contact_person.forEach(item => {
                    contact_person.push({
                        id:item.uid,
                        contact_name: item.contact_name,
                        contact_phone: item.contact_phone,
                        contact_type: item.contact_type,
                    })
                })
                let obj = {
                    ...value,
                    group_id: value.group_id.pop(),
                    facility: JSON.stringify(arr),
                    contact_data: JSON.stringify(contact_person)
                };

                try {
                    modify({
                        data: obj
                    }).then(res => {
                        this.$router.go(-1);
                    })

                } catch {}
            },
            untie_wx_mini(data) {
                cancelBinding({
                    data: {
                        id: data.id
                    },
                    info: true,
                }).then(res => {
                    this.get_list(this.$route.query.id)
                })
            }
        }
    };
</script>
<style lang="less" scoped>
</style>
